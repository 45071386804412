<template>
  <router-view v-if="!loading" />
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'EmbedLayout',
  computed: {
    ...mapState({
      loading: state => {
        const {
          account, users, customer, payment,
        } = state.api.loading
        return account || users || customer || payment
      },
    }),
  },
  watch: {
    loading(isLoading) {
      const loadingScreen = document.getElementById('loading-bg')
      if (!isLoading) {
        if (loadingScreen) loadingScreen.style.display = 'none'
      }
    },
  },
  mounted() {
    const loadingScreen = document.getElementById('loading-bg')
    if (this.loading) loadingScreen.style.display = 'block'
  },
}
</script>
